import request from 'utils/request';
const api = {
  ADV_LIST: '/api/finance/adv/list',
  CREATE_INVOICE: '/api/finance/adv/createInvoice?id=',
  SEND_EMAIL: '/api/finance/adv/sendEmail?id=',
  CREATE_PDF: '/api/finance/adv/createPdf?id=',
  ADV_DOWNLOAD: '/api/finance/adv/download',
  MERGE_ADV: '/api/finance/adv/mergeAdv',
  DELETE_ADV: '/api/finance/adv/delete?id=',
  CANCEL_ADV: '/api/finance/adv/cancel?id=',
};

/**
 * 获取 ADV对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.ADV_LIST,
    method: 'get',
    params,
  });
}

export function creatInovicePdf(id) {
  return request({
    url: api.CREATE_INVOICE + id,
    method: 'get',
    id,
  });
}

export function createPdf(id) {
  return request({
    url: api.CREATE_PDF + id,
    method: 'get',
    id,
  });
}

export function sendEmail(id) {
  return request({
    url: api.SEND_EMAIL + id,
    method: 'get',
    id,
  });
}

export function save(data) {
  return request({
    url: api.FINANCE_CONTRACT_ADD,
    method: 'post',
    data,
  });
}

export function download(params) {
  return request({
    url: api.ADV_DOWNLOAD,
    method: 'get',
    params,
  });
}

export function mergeAdv(data) {
  return request({
    url: api.MERGE_ADV,
    method: 'post',
    data,
  });
}

export function deleteAdv(id) {
  return request({
    url: api.DELETE_ADV + id,
    method: 'delete',
    id,
  });
}

export function cancelAdv(id) {
  return request({
    url: api.CANCEL_ADV + id,
    method: 'delete',
    id,
  });
}
